import { isNonEmptyArray } from '@apollo/client/utilities';
import baseTheme from '@bottlebooks/gatsby-theme-event/src/theme';
import merge from 'lodash/merge';
const theme = {
  ...baseTheme,
  fontUrls: ['https://fonts.googleapis.com/css2?family=Raleway&display=swap'],

  colors: merge({}, baseTheme.colors, {
    text: '#3E4857',
  }),

  fonts: merge({}, baseTheme.fonts, {
    heading: 'Raleway, sans-serif',
    body: 'Raleway, sans-serif',
  }),

  fontWeights: merge({}, baseTheme.fontWeights, {
    heading: '600',
    semititle: '500',
    body: '400',
  }),

  text: merge({}, baseTheme.text, {
    // The region heading of "Explore by Producer".
    groupLabel: {
      textTransform: 'uppercase',
      fontFamily: 'body',
      fontSize: 'small',
      fontWeight: 'bold',
    },
    // The producer name in each grid item.
    gridLabel: {
      textTransform: 'uppercase',
      fontFamily: 'body',
      fontSize: 'small',
      fontWeight: 'body',
    },
    largeGridLabel: {
      textTransform: 'uppercase',
      fontFamily: 'body',
      fontSize: '18px',
      fontWeight: 'semi-bold',
    },
  }),
};

// console.log(theme);
export default theme;
